.background {
    background: #EEEEEE; 
    padding: 10px; 
}

.hero {
    padding: 8%; 
}

.about-container { 
    background: #ffffff; 
    padding: 36px; 
    box-shadow: 0 0 10px
    rgba(0, 0, 0, 0.1); 
    margin-top: 3rem; 
    margin-bottom: 3rem; 
    height: auto; 
} 

h1 {
    font-family: "Noto Serif SC",serif; /* padding-left: 15px; */ 
} 
    
.h2, h2 {
    font-size: 2rem; 
    font-size: 20px; 
    text-transform: uppercase; 
    font-weight: 300; 
}

.img-container { 
    min-width: 250px; 
    width: 41.666667%; 
    max-width: 500px; 
    /* float: right;  */
    margin: 20px auto;
} 

.vertical-spacing { 
    height: 1.5rem; 
} 

.h3, h3 { 
    font-size:
    1.75rem; font-size: 20px; 
}