*, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
   }
  
  /* html
  {
      font-size:100%;
  } */
  
  /* body
  {
      -webkit-font-smoothing:antialiased;
      color:#333332;
      font-family:Lora, serif;
      font-size:18px;
      font-weight:400;
      line-height:1.4;
      text-rendering:optimizeLegibility;
  } */
  
  .skill-set li:hover
  {
      background:#3498db;
  }
  
  /* h1
  {
      color:rgba(0,0,0,.75);
  } */
  
  .wrapper
  {
      height:100%;
  }
  
  .name-hero
  {
      background:rgba(0,0,0,.001);
      bottom:0;
      height:290px;
      left:0;
      margin:auto;
      position:absolute;
      right:0;
      top:0;
      width:85%;
  }
  
  .me-img
  {
      background:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/36334/profile/profile-512_4.jpg) no-repeat center center ;
      background-size:100%;
    background-position:0px;
      border-radius:100%;
      height:150px;
      margin:0 auto;
      position:relative;
      width:150px;
  }
  
  .name-hero h1
  {
      font-family:Open Sans, sans-serif;
      font-size:1.5em;
      text-align:center;
  }
  
  .name-hero h1 em
  {
      color:rgba(0,0,0,.3);
      font-style:normal;
      font-weight:700;
  }
  
  .name-hero p
  {
      color:rgba(0,0,0,.5);
      font-size:.75em;
      line-height:1.5;
      margin:0 8px 0 0;
      text-align:center;
  }
  
  .name-hero .name-text
  {
      margin:0 auto;
      width:85%;
  }
  
  .inner
  {
      margin:0 auto;
      max-width:975px;
      padding: 0 3em;
  }
  
  .inner h1
  {
      font-size:1.75em;
  }
  
  .inner p
  {
      color:rgba(0,0,0,.3);
  }
  
  .inner p em
  {
      color:rgba(0,0,0,.75);
      font-style:normal;
  }
  
  .inner section
  {
      margin:65px auto;
  }
  
  .skill-set ul
  {
      list-style-type:none;
      margin-top:-10px;
      max-width:570px;
      padding:0;
  }
  
  .skill-set li
  {
      background:rgba(0,0,0,.75);
      border-radius:5px;
      color:#FFF;
      display:inline-block;
      list-style:none;
      margin:15px 15px 0 0;
      padding:10px;
      text-align:justify;
  }
  
  .clearfix:after {
     content: " "; /* Older browser do not support empty content */
     visibility: hidden;
     display: block;
     height: 0;
     clear: both;
  }