body {
    margin: 0; 
}

/* h1 {
    font-family: 'Noto Serif SC', serif; 
    padding-left: 15px;
} */

/* p {
    font-family: 'Open Sans', sans-serif; 
    padding: 0 8px 0 15px;
} */

.text-emphasis {
    color: purple; 
    font-weight: 600;
}

.left {
    width: 38%;
}

.left > img {
    width: 400px; 
    padding: 8px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 7px rgba(34,34,34,0.6);
    display: block;
    margin: 0 auto;
    margin-bottom: 5%; 
    transform: rotate(-3deg) translateY(9px);     
}

.right {
    width: 45%;
    background-color: white;
    padding: 15px;
    border-radius: 2px;
    box-shadow: 0px 0px 15px rgba(34,34,34,0.6); 
}

.footer {
    /* position: fixed; */
    /* left: 0;
    bottom: 0; */
    width: 100%;
    height: 45px;
    padding-top: 10px;
    background-color: #ffffff;
    text-align: center;
}