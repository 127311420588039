@media (min-width: 62em) {
    .left > img {
        float: right; 
        margin-bottom: 0; 
    }
}

@media (max-width: 600px) {
    .left > img {
        width: 340px; 
    }
}

@media (min-width: 758px) {
    .img-container {
        margin: 20px 0 20px 20px; 
    }
}

@media screen and (max-width: 48em) {
    .right,.left
    {
        float:none;
        position:relative !important;
        width:100%;
        min-height:500px;
    }
  .handmade {
    text-align:center !important;
    margin-top:0px !important;
}
}

/* @media screen and (max-width: 75em) {
    body
{ font-size:16px;}
}

@media screen and (max-width: 60em) {
    body
{ font-size:14px;}
} */